import { computed, persistedState, reactive } from "#imports";
import { acceptHMRUpdate, defineStore, skipHydrate } from "pinia";

interface UserState {
  token: string | number | undefined;
  userInfo: any;
}

function storeSetup() {
  let state: UserState = reactive({
    token: "",
    userInfo: {},
  });

  const getToken = computed(() => {
    return state.token || "";
  });

  const isLogin = computed(() => {
    return !!getToken.value;
  });

  const getUserInfo = computed(() => {
    return state.userInfo || {};
  });

  const setToken = (_token: string | number | undefined) => {
    state.token = _token;
  };

  const setUserInfo = (_userInfo: any) => {
    state.userInfo = _userInfo;
  };

  // return { state: skipHydrate(state), getToken, setToken };
  return { state, getToken, isLogin, getUserInfo, setToken, setUserInfo };
}

export const useUserStore = defineStore("rootlist-fe-user", storeSetup, {
  // persist: {
  //   storage: persistedState.localStorage || persistedState.sessionStorage,
  // },
  persist: true,
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot));
}
